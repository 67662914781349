.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.video {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-family:'kotrablodkr';
  src: url(component/fonts/KOTRA_BOLD.ttf);
}

@font-face {
    font-family:'maplelight';
    src: url(component/fonts/Maplestory_Light.ttf);
}

@font-face {
  font-family: 'maplebold';
  src: url(component/fonts/Maplestory_Bold.ttf);
}

.kotrablod {
  font-family:'kotrablodkr';
}
.maplelight {
  font-family: 'maplelight';
}
.maplebold {
  font-family: 'maplebold';
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Padding {
  margin: 2em 16em;
}

.PaddingTop {
  margin-top: -125px;
}

.Bold1 {
  font-weight: bold;
}

.Big {
  font-weight: bold;
  font-size: 2.6rem;
}

.BigEM {
  font-size: 1.6rem;
}

.BigEMBold {
  font-weight: bold;
  font-size: 1.6rem;
}

.Small {
  font-size: 1.2rem;
}

.card{
  cursor: pointer;
}

hoverblue:hover {
  background-color: lightskyblue;
}

.slick-prev:before,
.slick-next:before{
  color: blue !important;
}

.slick-wrapper{
  padding: 0 4rem;
}

.yellow-box {
  background-color: yellow;
  border: 3px solid black;
  /* text-align: center; */
  /* width: flex; */
  position: relative;
  height: 150px;
}

.centerbox {
  position: absolute;
  top: 50%;
  left: 50%;
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

main{
  padding-bottom: 20px;
}

footer{
  margin-top: auto;
}

/*new styles*/

.featured-news{
  max-width: 1000px;
  width: 94%;
  margin: 2em auto;
}

.a-custom{
  text-decoration: none;
  color: black;
}

.ScalingTextTitle{
    font-size: 32px;
    font-size: 5vw;
}

.ScalingTextBody{
  font-size: 10px;
  font-size: 2.5vw;
}

.ScalingTextDescrip{
  font-size: 6px;
  font-size: 1vw;
}

.TierTag{
  text-align: left;
  padding-top: 0.5em;
}

.ArticleHeader{
  font-size:20px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



@media screen and (max-width: 480px){
  .navbar-brand{
    display: flex;
    flex-direction: column;
  }
  .navbar-brand > span:last-child{
    text-align: left !important;
  }
  .LtabText{
    font-size:15px;
  }
  .ArticleHeader{
    font-size:17px;
  }
  h5{
    font-size:14px;
  }
  p{
    font-size:12px;
  }
  .Big {
    font-size: 28px;
  }
  .Small {
    font-size: 10px;
  }
}

.GradientBackground1 {
  background: rgb(6, 0, 107);
  background: linear-gradient(90deg, rgba(6, 0, 107, 1) 0%, rgba(21, 21, 224, 1) 7%, rgba(0, 212, 255, 1) 100%);
}

/* .GradientBackground1 {
  background: rgb(6, 0, 107);
  background: linear-gradient(90deg, rgb(36, 197, 230) 17%, rgba(21, 21, 224, 1) 77%, rgba(6, 0, 107, 1)100%);
} */

.BG1 {
  background-image: "url(/img/ArticleBG/back1.webp)";
  background-attachment: fixed;
}

.BG2{
  background-image: url(../public/img/ArticleBG/bg.webp);
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

.CastShadow {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}